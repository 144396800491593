import { importAll } from 'ext/utils'
import 'slick-carousel'

importAll(require.context('.', true, /index.sass$/, 'lazy'))

const slickInit = () => {
  const container = $('#slider_payment_details')
  if (!container || container.length === 0) return

  container.get(0).dataset.slideCount = container.children().length

  container.slick({
    responsive: [
      {
        breakpoint: 100000,
        settings: 'unslick'
      },
      {
        breakpoint: 1023,
        settings: {
          dots: true,
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          centerMode: false,
          variableWidth: true
        }
      }
    ]
  })
}

// setTimeout(slickInit, 0)
//
// addEventListener('resize', slickInit)

const termsAndConditionsCheckbox = document.querySelector('[data-ref="terms_and_conditions"]') || document.querySelector('[data-ref="privacy_checkbox_consent"]')
const repeatConfirmButton = document.querySelector('[data-ref="repeat_confirm"]')
const hint = document.getElementById('hint_text')

if (termsAndConditionsCheckbox) {
  termsAndConditionsCheckbox.addEventListener('change', function() {
    disableSubmitBtn()
  });

  disableSubmitBtn(); // call the function initially to set the state
}

function disableSubmitBtn() {
  const checked = termsAndConditionsCheckbox.checked;
  if (repeatConfirmButton) repeatConfirmButton.disabled = !checked
  const hint = document.getElementById('hint') // or document.querySelector('#hint')
  if (hint) hint.style.display = checked ? 'none' : 'block'
}
