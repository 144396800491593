import 'jquery-validation'

export default class LibIssuePopup {
  constructor() {
    this.link = $('[role="issue"]')
    this.popup = $('[role="issuePopup"]')
    this.form = $('[role="issueForm"]')
    this.backdrop = $('[role="backdrop"]')
    this.contactPopup = $('#fc_frame')
    this.closeCross = $('[role="close"]')
    this.inputs = $('[role="checkValid issueField autosize"]')
    this.successMark = $('[role="successMark"]')
    this.submitBtn = $('[role="submitIssue"]')
    this.formFloatGroup = $('[role="formFloatGroup"]')
    this.problemType = $('[role="problemType"]')

    this.data = this.link.data()

    this.link.on('click', () => this.showPopup())

    this.backdrop.on('click', () => this.hidePopup())

    this.closeCross.on('click', () => this.hidePopup())

    this.validator = $(this.form).validate({
      rules: {
        'troubleshooting_log[mobile_phone]': {
          required: true,
          exactlength: [10, 10],
          sameNumbers: true,
          dontNumbersConsecutive: true,
          not_landline_phone: true,
          not_double_zero: true,
          not_first_one: true
        },
        'troubleshooting_log[message]': {
          required: true,
          minWords: 2,
          maxlength: 250,
          notonlyspaces: true
        }
      }
    })

    $(this.form).on('submit', event => {
      event.preventDefault()

      const data = this.formData()
      data.push(this.getBrowserInfo())

      if (this.isValid()) {
        this.submitBtn.attr('disabled', true)
        $.ajax({
          type: 'POST',
          dataType: 'json',
          data,
          url: '/troubleshooting_log',
          success: _data => this.sendSuccess()
        })
      }
    })

    this.inputs.on('change', e => {
      const element = $(e.target)
      const valLength = element.val().length
      const invalid = element.attr("aria-invalid")
      if (invalid === 'false' && valLength > 0)
        element.closest('div').addClass('input__valid')
      else
        element.closest('div').removeClass('input__valid')
    })
  }

  sendSuccess() {
    this.successMark.removeClass('hidden')
    this.submitBtn.addClass('hidden').attr('disabled', false)

    setTimeout(() => {
      this.hidePopup()
      this.clearForm()
    }, 1000)
  }

  getBrowserInfo() {
    return { name: 'troubleshooting_log[browser_info]', value: navigator.userAgent }
  }

  isValid() {
    return this.validator.form()
  }

  showPopup() {
    const mobilePhone = $('#troubleshooting_log_mobile_phone')
    const application = $('#troubleshooting_log_application_id')

    mobilePhone.val(this.data.mobilePhone)
    application.val(this.data.applicationId)

    this.formFloatGroup.addClass('form_float__group-active')
    this.problemType.val(window.location.href)

    this.contactPopup.hide()
    this.successMark.addClass('hidden')
    this.submitBtn.removeClass('hidden')
    this.backdrop.removeClass('hidden')
    this.popup.removeClass('hidden')
  }

  hidePopup() {
    this.contactPopup.show()
    this.popup.addClass('hidden')
    this.backdrop.addClass('hidden')
  }

  formData() {
    return this.form.serializeArray()
  }

  clearForm() {
    this.inputs.val('').closest('div').removeClass('form_float__group-active')
    $('#select2-issue_problem_type-container').text(gon.translations['issue.problemTypePlaceholder'])
  }
}
