export default class LibPersonalAccountGA {
  constructor() {
    if (!window.dataLayer) return false
    const html = $('html')

    const lkMain = html.find('[role="lkMain"]')
    const bannerMain = html.find('[role="bannerMain"]')
    const takeMoneyMain = html.find('[role="takeMoneyMain"]')
    const loginMobileButton = html.find('[role="loginMobileButton"]')

    lkMain.click(() => {
      // dataLayer.push({ applicationStatus: 'lk_main'}) if dataLayer?
      if (typeof dataLayer === 'undefined' && dataLayer === null) return false

      dataLayer.push({ event: 'event', eventCategory: 'Login', eventAction: 'banner_main' })
    })

    bannerMain.click(() => {
      // dataLayer.push({ applicationStatus: 'banner_main'}) if dataLayer?
      if (typeof dataLayer === 'undefined' && dataLayer === null) return false

      dataLayer.push({ event: 'event', eventCategory: 'Login', eventAction: 'banner_main' })
    })

    takeMoneyMain.click(() => {
      // dataLayer.push({ applicationStatus: 'take_money_main'}) if dataLayer?
      if (typeof dataLayer === 'undefined' && dataLayer === null) return false

      dataLayer.push({ event: 'event', eventCategory: 'Login', eventAction: 'take_money_main' })
    })

    loginMobileButton.click(() => {
      if (typeof dataLayer === 'undefined' && dataLayer === null) return false

      dataLayer.push({ event: 'event', eventCategory: 'mobile', eventAction: 'login_mobile_button' })
    })
  }
}
