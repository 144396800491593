import 'global/lib/jquery_validation'
import 'ext/patching'

import Global from 'global/application'
import Application from 'desktop/application'

import { ready } from 'ext/utils'
import '../init/ujs'
import '../init/turbolinks'

import '../styles/application'
import /* webpackMode: "lazy-once" */ '../components/main'

require.context('../images', true)

ready(() => {
  window.Global = new Global($(document))
  window.Application = new Application($(document))

  // HACK: Ghostbusters. Strange qwerty removing
  document.querySelectorAll('p').forEach(p => {
    if (p.innerText === 'qwerty qwerty') p.remove()
  })

  document.querySelectorAll('[data-dismiss="modal"]').forEach(button => {
    button.addEventListener('click', e => {
      e.preventDefault()
      e.target.closest('.modal').classList.remove('open')
    })
  })

  document.querySelectorAll('.modal-backdrop').forEach(backdrop => {
    backdrop.addEventListener('click', () => {
      document.querySelectorAll('.modal.open').forEach(modal => {
        modal.classList.remove('open')
      })
    })
  })
})
