import { importAll } from 'ext/utils'

importAll(require.context('.', true, /index.sass$/))

document.querySelectorAll('a[data-toggle="collapse"]').forEach(el => {
  el.addEventListener('click', e => {
    e.preventDefault()
    // const scrollPosition = [window.scrollX, window.scrollY]
    //
    // window.scrollTo.apply(window, scrollPosition)

    const target = $(e.currentTarget)
    $('html, body').animate({
      scrollTop: $(target).offset().top - 12
    }, { duration: 500 })
    return false
  })
})
