import { intersect } from 'ext/utils'

export default class LibOptionalFields {
  constructor(container) {
    $.each(container.find('[data-if]'), (index, element) => {
      element = $(element)

      const parent = container.find(element.data('if'))

      parent.on('change.dataIf', _event => {
        const target = $(this)
        let selected

        if (target.is('[type=checkbox]') || target.is('[type=radio]'))
          selected = target.is(':checked') ? target.val() : ''
        else
          selected = target.val()

        if (intersect([String(selected)], String(element.data('if-value')).split('|')).length !== 0) {
          element.show()
          element.find('input[data-disabled!=true], select[data-disabled!=true]').attr('disabled', false)
        } else {
          element.hide().find('input, select').attr('disabled', true)
        }
      })

      if (parent.is('[type=radio]') && parent.filter(':checked').length > 0)
        parent.filter(':checked').trigger('change.dataIf')
      else
        parent.trigger('change.dataIf')
    })
  }
}
