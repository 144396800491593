// import Inputmask from 'inputmask'
const { translations } = window.gon

class DesktopLibFloatLabel {
  constructor(container) {
    for (const group of container.find('[role="formFloatGroup"]')) {
      new DesktopLibFloatLabelInput($(group))
    }
  }
}

class DesktopLibFloatLabelInput {
  constructor(group) {
    this.group = group
    this.input = group.find('input[type="text"], input[type="tel"], input[type="number"], input[type="email"], textarea')
    this.select = group.find('select')

    if (this.input.length > 0) {
      this.active(this.input)
      this.activateAutofocus(this.input)

      this.input.off('focus').on('focus', (e) => {
        if (e.target.id === 'application_mobile_phone')
          $('label[for="application_mobile_phone"]').closest('label').text(translations['activerecord.attributes.mobilePhone'])

        this.addMaskToPhone()

        if (!e.currentTarget.readOnly) {
          this.group.addClass('form_float__group-focused')
          this.active($(e.currentTarget))
        }
      })

      this.input.off('blur').on('blur', (e) => {
        if (e.target.id === 'application_mobile_phone' && $(e.target).val() === "") {
          $('label[for="application_mobile_phone"]').closest('label').text(translations['activerecord.attributes.newMobilePhone'])
          // found only this way of removing mask for mobile phone
          this.addMaskToPhone()
        }

        if (!e.currentTarget.readOnly) {
          this.group.removeClass('form_float__group-focused')
          this.active($(e.currentTarget))
        }
      })
    }

    if (this.select.length > 0) {
      this.active(this.select)

      this.select.on("select2:open", _e => this.group.addClass('form_float__group-focused'))
      this.select.on("select2:close", _e => this.group.removeClass('form_float__group-focused'))
      this.select.on("select2:select", _e => this.group.addClass('form_float__group-active'))
      this.select.on("select2:unselect", _e => this.group.removeClass('form_float__group-active'))
    }
  }

  activateAutofocus(el) {
    if (el.is(':focus'))
      this.group.addClass('form_float__group-focused')
  }

  active(el) {
    if (el?.val()?.length > 0)
      this.group.addClass('form_float__group-active')
    else
      this.group.removeClass('form_float__group-active')
  }

  addMaskToPhone() {
    const phoneInput = $("[data-phone-mask='mobilePhoneMask']")
    if (!phoneInput || phoneInput.length === 0) return

    new Inputmask({
      placeholder: "X",
      regex: "^0[35789][0-9]{1} [0-9]{3} [0-9]{4}$",
      showMaskOnHover: false,
      greedy: false,
      showMaskOnFocus: false
    }).mask(phoneInput)
  }
}

export default DesktopLibFloatLabel
