(function ($) {
  const Defaults = $.fn.select2.amd.require('select2/defaults')

  $.extend(Defaults.defaults, {
    searchInputPlaceholder: ''
  })

  const SearchDropdown = $.fn.select2.amd.require('select2/dropdown/search')

  const renderSearchDropdown = SearchDropdown.prototype.render

  SearchDropdown.prototype.render = function (decorated) {
    // invoke parent method
    const $rendered = renderSearchDropdown.apply(this, Array.prototype.slice.apply(arguments))

    this.$search.attr('placeholder', this.options.get('searchInputPlaceholder'))

    return $rendered
  }
})(window.jQuery)
