import LibDatePicker from 'global/lib/date_picker'
import LibOptionalFields from 'global/lib/optional_fields'
import LibPersonalAccountGA from 'global/lib/personal_account_ga'
import AutoNumeric from 'autonumeric/src/main'
import Inputmask from 'inputmask'
import 'bootstrap-sass/assets/javascripts/bootstrap'
require('unveil')

export default class Global {
  constructor(container) {
    this.container = container

    new LibDatePicker(container)
    new LibOptionalFields(container)
    new LibPersonalAccountGA()

    this.navbarBurger = container.find('[role="navbarBurger"]')
    this.darkBg = container.find('[role="darkBg"]')

    const emailIdent = 'input[role="email"]'
    const emailInput = document.querySelector(emailIdent)
    if (emailInput) {
      const defaultValue = JSON.parse(localStorage.getItem('browser_storage_list'))
      emailInput.value = defaultValue['application[email]']?.value || ''
    }

    const currencyIdent = 'input[role="currency"]'
    const currencyInput = document.querySelector(currencyIdent)
    if (currencyInput) {
      const defaultValue = JSON.parse(localStorage.getItem('browser_storage_list'))
      currencyInput.value = defaultValue['application[salary]']?.value || ''

      new AutoNumeric(currencyIdent, {
        currencySymbol: '',
        digitGroupSeparator: '.',
        minimumValue: '0',
        decimalCharacter: ',',
        decimalPlaces: '0',
        modifyValueOnWheel: false,
        maximumValue: '99999999'
      })
    }

    const phoneInput = document.querySelector('[data-phone-mask="mobilePhoneMask"]')
    if (phoneInput) {
      new Inputmask({
        placeholder: 'X',
        regex: '^0[35789][0-9]{1} [0-9]{3} [0-9]{4}$',
        showMaskOnHover: false,
        greedy: false,
        showMaskOnFocus: false
      }).mask(phoneInput)
    }

    const companyPhoneInput = document.querySelector('[role="companyPhoneMask"]')
    if (companyPhoneInput) {
      new Inputmask({
        regex: '^(0[35789][0-9]{1} [0-9]{3} [0-9]{4})|(02[0-9]{1} [0-9]{4} [0-9]{3,4})$',
        showMaskOnHover: false,
        greedy: false,
        showMaskOnFocus: false
      }).mask(companyPhoneInput)
    }

    const promoCode = document.querySelector('[role="promoCode"]')
    if (promoCode) {
      new Inputmask({
        mask: '***',
        showMaskOnHover: false,
        greedy: false
      }).mask(promoCode)
    }

    container.find('[role="modal"]').on('hidden.bs.modal', e => {
      $(e.currentTarget).removeData('bs.modal')
      $(e.currentTarget).find('[role="modalContent"]').empty()
    })

    container.on('ajax:success', 'a[data-modal=true]', event => {
      const { response, _status } = event.detail[2]
      this.showModal(response, event.target)
    })

    container.on('ajax:success', 'form[role="formAcc"]', event => {
      window.location = event.detail[2].responseURL
      if (event.detail[2].responseURL) $('.account_body__btn').attr('disabled', true)
    })

    container.on('ajax:error', 'form[role="formAcc"]', event => {
      container.find('[role="modalAcc"]').html(event.detail[0])
    })

    $('.personal_account').hover(e => {
      $(e.currentTarget).find('.drop-menu').stop(true, true).delay(200).fadeIn(100)
    }, e => {
      $(e.currentTarget).find('.drop-menu').stop(true, true).delay(200).fadeOut(100)
    })

    container.find('[role="facebookButton').click(e => {
      const width = 600
      const height = 400
      const left = (screen.width / 2) - (width / 2)
      const top = (screen.height / 2) - (2 * height / 3)
      const features = `menubar=no,toolbar=no,status=no,width=${ width },height=${ height },toolbar=no,left=${ left },top=${ top}`
      const loginWindow = window.open('/auth/facebook/', '_blank', features)

      loginWindow.focus()
      e.preventDefault()

      this.trackButtonClick('facebook')
      return false
    })

    container.find('[role="zaloButton"]').click(e => {
      const width = 600
      const height = 400
      const left = (screen.width / 2) - (width / 2)
      const top = (screen.height / 2) - (2 * height / 3)
      const features = `menubar=no,toolbar=no,status=no,width=${ width },height=${ height },toolbar=no,left=${ left },top=${ top}`
      const loginWindow = window.open('/auth/zalo/', '_blank', features)

      loginWindow.focus()
      e.preventDefault()

      this.trackButtonClick('zalo')
      return false
    })

    $('img').unveil(200, e => {
      $(e.currentTarget).load(e => {
        e.currentTarget.style.opacity = 1
      })
    })

    this.navbarBurger.click(e => {
      $(e.currentTarget).toggleClass('active')
      $('[role="navbarNav"]').toggleClass('active')
      $('[role="darkBg"]').toggleClass('active')
      $('.body').toggleClass('active')
      return false
    })

    $('[role="darkBg"]').click(e => {
      $(e.currentTarget).removeClass('active')
      $('[role="navbarNav"]').removeClass('active')
      this.navbarBurger.removeClass('active')
      $('.body').removeClass('active')
    })
  }

  showModal(data, _trigger) {
    this.container.find('[role="modalContent"]').html(data)
    window.currentModal = this.container.find('[role="modal"]')
    currentModal.modal('show')
  }

  trackButtonClick(provider) {
    $.ajax({
      type: 'PATCH',
      dataType: 'json',
      url: '/application/omni_auth_button_behavior/track_click',
      data: {
        provider: provider
      }
    })
  }
}
