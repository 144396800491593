export default class VideoInstructionPopup {
  constructor() {
    this.link = $('#videoInstruction')
    this.closeCross = $('#close')

    this.link.on('click', () => this.showPopup())

    this.closeCross.on('click', () => this.hidePopup())
  }

  showPopup() {
    this.backdrop = $('#backdrop')
    this.popup = $('#videoPopup')
    this.backdrop.removeClass('hidden')
    return this.popup.removeClass('hidden')
  }

  hidePopup() {
    this.backdrop = $('#backdrop')
    this.popup = $('#videoPopup')
    this.stopVideo()
    this.popup.addClass('hidden')
    return this.backdrop.addClass('hidden')
  }

  stopVideo() {
    this.videoFrame = $('#videoInstructionFrame')
    this.videoFrame.attr('src', this.videoFrame.attr('src'))
  }
}
