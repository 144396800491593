import DesktopLibFloatLabel from 'desktop/lib/float_label'
import IssuePopup from 'global/lib/issue_popup'
import VideoInstructionPopup from 'global/lib/video_instruction_popup'
import { LibIsDesktop } from 'global/lib/browser_type'
import DesktopLibSelect2 from 'desktop/lib/select2'
import NProgress from 'nprogress'
const { start, done } = NProgress

export default class Application {
  constructor(container) {
    new IssuePopup
    new VideoInstructionPopup
    if (LibIsDesktop()) new DesktopLibSelect2(container)

    container.on('ajax:beforeSend', () => start())
    container.on('ajax:complete', () => done())

    setTimeout(() => new DesktopLibFloatLabel(container), 0)
  }
}
