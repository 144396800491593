import Inputmask from 'inputmask'

export default class LibDatePicker {
  constructor(container) {
    for (const item of container.find('[role="datePicker"]')) {
      new Inputmask({
        mask: "99.99.9999",
        greedy: false
      }).mask(item)
    }
  }
}
