import Turbolinks from 'turbolinks'

Turbolinks.start()

const reloadWithTurbolinks = () => {
  let scrollPosition

  function reload() {
    scrollPosition = [window.scrollX, window.scrollY]
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  window.addEventListener('turbolinks:load', function () {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition)
      scrollPosition = null
    }
  })

  return reload
}

window.addEventListener('popstate', () => requestAnimationFrame(reloadWithTurbolinks))

window.onerror = (message, file, line, col, error) => {
  if (error && error.message === 'MtPopUpList is not defined') return true
}
