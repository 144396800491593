import 'jquery-validation'
const { locale, translations } = window.gon;

(() => {
  if (locale === 'vi') {
    $.extend($.validator.messages, {
      acceptance: 'Hãy đánh dấu đồng ý nếu bạn muốn tiếp tục',
      required: 'Hãy nhập',
      remote: 'Hãy sửa cho đúng',
      email: 'Hãy nhập email',
      url: 'Hãy nhập URL',
      date: 'Hãy nhập ngày',
      dateISO: 'Hãy nhập ngày (ISO)',
      number: 'Hãy nhập số',
      digits: 'Hãy nhập chữ số',
      integer: 'Hãy nhập chữ số',
      creditcard: 'Hãy nhập số thẻ tín dụng',
      equalTo: 'Hãy nhập thêm lần nữa',
      extension: 'Phần mở rộng không đúng',
      maxlength: $.validator.format('Hãy nhập từ {0} kí tự trở xuống'),
      minlength: $.validator.format('Hãy nhập từ {0} kí tự trở lên'),
      rangelength: $.validator.format('Hãy nhập từ {0} đến {1} kí tự'),
      range: $.validator.format('Hãy nhập từ {0} đến {1}'),
      max: $.validator.format('Hãy nhập từ {0} trở xuống'),
      min: $.validator.format('Hãy nhập từ {1} trở lên'),
      lettersonly: 'Bắt buộc phải nhập',
      vidate: 'Vui lòng chỉ định ngày ở định dạng DD.MM.YYYY',
      minWords: 'Bắt buộc phải nhập',
      exactlength: 'Phải có {0} hoặc {1} số',
      min_length_salary: 'Please enter at least 7 characters.',
      notonlyspaces: 'Bắt buộc phải nhập',
      lettersdigits: 'Hãy nhập từ 3 kí tự trở xuống',
      not_landline_phone: 'Vui lòng cung cấp số điện thoại di động không bắt đầu bằng chữ số "2"',
      not_double_zero: 'Vui lòng cung cấp số điện thoại di động không bắt đầu bằng chữ số "0"',
      not_first_one: 'Vui lòng cung cấp số điện thoại di động không bắt đầu bằng chữ số "1"',
      minSymbols: 'Vui lòng cung cấp ít nhất {0} ký hiệu',
      maxSymbols: 'Vui lòng cung cấp ít nhất {0} chữ số',
      minDigits: 'Vui lòng cung cấp ít nhất {0} chữ số',
      maxDigits: 'Vui lòng cung cấp không quá {0} chữ số',
      validSalaryMin: 'Vui lòng cung cấp ít nhất {0} chữ số',
      validSalaryMax: 'Vui lòng cung cấp không quá {0} chữ số',
      maxAllowedSpaces: 'Vui lòng viết không quá {0} dấu cách',
      notTwospaces: 'Vui lòng chỉ để 1 khoảng trắng giữa các chữ trong họ tên',
      notPrefixSpace: 'Xin vui lòng, không sử dụng không gian như biểu tượng đầu tiên',
      validBirthDate: 'Quý khách phải trên 14 tuổi để nộp đơn vay',
      validBirthDateMax: 'Quý khách phải dưới 100 tuổi để nộp đơn vay',
      validIssueDate: 'Vui lòng chỉ định ngày ở định dạng DD.MM.YYYY. Không như vậy trong tương lai hay không như vậy trong quá khứ.',
      validLivingStreet: 'Vui lòng điền chính xác địa chỉ của bạn',
      validCompanyName: 'Tên công ty phải chứa ít nhất 2 ký hiệu và không nên bắt đầu bằng dấu cách. Phải chứa các chữ cái.',
      validDate: 'Vui lòng nhập ngày chính xác'
    })
  }

  if (locale === 'en') {
    $.extend($.validator.messages, {
      acceptance: 'Hãy đánh dấu đồng ý nếu bạn muốn tiếp tục',
      vidate: 'Please specify the date in DD.MM.YYYY format',
      lettersonly: 'Letters only please',
      minWords: $.validator.format('Please enter at least {0} words'),
      exactlength: $.validator.format('Should contain {0} or {1} numbers'),
      min_length_salary: 'Please enter at least 7 characters.',
      notonlyspaces: 'Letters only please',
      lettersdigits: 'Please enter at least 3 characters.',
      not_landline_phone: 'Please provide mobile number not started with "2" digit',
      not_double_zero: 'Please provide mobile number not started with "0" digit',
      minSymbols: 'Please provide at least {0} symbols',
      maxSymbols: 'Please provide not more than {0}symbols',
      minDigits: 'Please provide at least {0} digits',
      maxDigits: 'Please provide not more than {0} digits',
      validSalaryMin: 'Please provide at least {0} digits',
      validSalaryMax: 'Please provide not more than {0} digits',
      maxAllowedSpaces: 'Please write not more then {0} spaces',
      notTwospaces: 'Please, do not use double spaces',
      notPrefixSpace: 'Please, do not use space as first symbol',
      validBirthDate: 'Please enter correct date - day.month.year. We do not provide loans to people under 14 years of age',
      validBirthDateMax: 'Please enter correct date - day.month.year. We do not provide loans to people upper 100 years of age',
      validIssueDate: 'Please specify the date in DD.MM.YYYY format. Not so in future or not so in past.',
      validLivingStreet: 'Please fill your correct address',
      validCompanyName: "Company name should contain at least 2 symbols and shouldn't start with space. Must contain letters.",
      validDate: 'Please enter the correct date'
    })
  }

  jQuery.validator.setDefaults({
    errorClass: 'form_float__error',
    errorElement: 'span',
    errorPlacement: (error, element) => {
      const elem = $(element)
      if ($(element)
        .attr('type') === 'radio')
        error.appendTo(element.closest('.radio')
          .parent())
      else
        error.appendTo(element.parent())
    },
    highlight: (element, _errorClass, _validClass) => {
      $(element)
        .closest('.form-group')
        .addClass('form_float__group-error')
      $(element)
        .siblings('.form_float__error')
        .remove()
    },
    unhighlight: (element, _errorClass, _validClass) => {
      $(element)
        .closest('.form-group')
        .removeClass('form_float__group-error')
      $(element)
        .siblings('.form_float__error')
        .remove()
    }
  })

  $.validator.addMethod('acceptance', (value, _element) => typeof value !== 'undefined' || value)

  $.validator.addMethod('phone', function (phone_number, element) {
    phone_number = phone_number.replace(/\s+|_+|\+/g, '')
    return this.optional(element) || phone_number.length > 9
  })

  $.validator.addMethod('not_landline_phone', function (phone_number, element) {
    phone_number = phone_number.replace(/\s+|_+|\+/g, '')
    return this.optional(element) || !phone_number.startsWith('02')
  })

  $.validator.addMethod('not_double_zero', function (phone_number, element) {
    phone_number = phone_number.replace(/\s+|_+|\+/g, '')
    return this.optional(element) || !phone_number.startsWith('00')
  })

  $.validator.addMethod('not_first_one', function (phone_number, element) {
    phone_number = phone_number.replace(/\s+|_+|\+/g, '')
    return this.optional(element) || !phone_number.startsWith('01')
  })

  $.validator.addMethod('lettersonly', function (value, element) {
    return this.optional(element) ||
      /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêệếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạầảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựýýỳỵỷỹAÁẢÀÃẠÂẤẨẦẪẬĂẮẲẰẴẶOÓỎÒÕỌÔỐỔỒỖỘƠỚỞỜỠỢEÉẺÈẼẸÊẾỂỀỄỆUÚỦÙŨỤƯỨỬỪỮỰIÍỈÌĨỊĐaáảàãạâấẩầẫậăắẳằẵặoóỏòõọôốổồỗộơớởờỡợeéẻèẽẹêếểềễệuúủùũụưứửừữựiiỉìĩịđ\s]*$/.test(value)
  })

  $.validator.addMethod('notonlyspaces', function (value, element) {
    return this.optional(element) || !/^\s+$/.test(value)
  })

  $.validator.addMethod('lettersdigits', function (value, element) {
    return this.optional(element) ||
      /^[0-9a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêệếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạầảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựýýỳỵỷỹAÁẢÀÃẠÂẤẨẦẪẬĂẮẲẰẴẶOÓỎÒÕỌÔỐỔỒỖỘƠỚỞỜỠỢEÉẺÈẼẸÊẾỂỀỄỆUÚỦÙŨỤƯỨỬỪỮỰIÍỈÌĨỊĐaáảàãạâấẩầẫậăắẳằẵặoóỏòõọôốổồỗộơớởờỡợeéẻèẽẹêếểềễệuúủùũụưứửừữựiiỉìĩịđ\s]*$/.test(value)
  })

  $.validator.addMethod('minWords', function (value, element, params) {
    return this.optional(element) || value.trim()
      .split(/[\s.,\/()\n]+/).length >= params
  })

  $.validator.addMethod('minSymbols', (value, element, params) => {
    return value.length >= params
  }, translations['errors.messages.referralCode'])

  $.validator.addMethod('maxSymbols', (value, element, params) => {
    return value.length <= params
  }, translations['errors.messages.referralCode'])

  $.validator.addMethod('minDigits', (value, element, params) => {
    // check that minimum digits present in text field
    // There can be other symbols
    const only_digits = value.split(/\D/)
      .join('')
    return only_digits.length >= params
  })

  $.validator.addMethod('minDigitsCompanyPhoneWith02', (value, element, params) => {
    // check that minimum digits present in text field
    // There can be other symbols
    const only_digits = value.split(/\D/)
      .join('')
    const first_two_digits = only_digits.substring(0, 2)
    if (first_two_digits === '02')
      return only_digits.length >= params
    else
      return true
  }, translations['errors.messages.companyPhoneWith02'])

  $.validator.addMethod('minDigitsCompanyPhoneWithout02', (value, element, params) => {
    // check that minimum digits present in text field
    // There can be other symbols
    const only_digits = value.split(/\D/)
      .join('')
    const first_two_digits = only_digits.substring(0, 2)
    if (first_two_digits !== '02')
      return only_digits.length >= params
    else
      return true
  }, translations['errors.messages.companyPhoneWithout02'])

  $.validator.addMethod('maxDigits', (value, element, params) => {
    // check that maximum digits present in text field
    // There can be other symbols
    const only_digits = value.split(/\D/)
      .join('')
    return only_digits.length <= params
  })

  $.validator.addMethod('maxAllowedSpaces', function (value, element, params) {
    return this.optional(element) || value.split(' ').length <= params + 1
  })

  $.validator.addMethod('notTwospaces', function (value, element) {
    return this.optional(element) || !value.includes('  ')
  })

  $.validator.addMethod('notPrefixSpace', function (value, element) {
    return this.optional(element) || !value.startsWith(' ')
  })

  $.validator.addMethod('validBirthDate', (value, _element) => {
    const parts = value.split('-')
    const js_date = new Date(parts[2], parts[1] - 1, parts[0])
    const now = new Date()
    const first_allowed_birth_date = new Date(now.getFullYear() - 14, now.getMonth(), now.getDate() + 1)
    return js_date <= first_allowed_birth_date && js_date <= now
  })

  $.validator.addMethod('validBirthDateMax', (value, _element) => {
    const parts = value.split('-')
    const js_date = new Date(parts[2], parts[1] - 1, parts[0])
    const now = new Date()
    const last_allowed_birth_date = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate())
    return js_date >= last_allowed_birth_date && js_date <= now
  })

  $.validator.addMethod('validIssueDate', (value, element) => {
    const parts = value.split('.')
    const js_date = new Date(parts[2], parts[1] - 1, parts[0])
    const now = new Date()
    const first_allowed_issue_date = new Date(1957, 1, 1)
    return js_date > first_allowed_issue_date && js_date <= now
  })

  $.validator.addMethod('validLivingStreet', (value, _element) => {
    const symbolsCount = value.split(/[\s]*/).length
    return !(symbolsCount < 2 || value.startsWith(' '))
  })

  $.validator.addMethod('validCompanyName', (value, _element) => {
    const symbolsCount = value.split(/[\s]*/).length
    if (symbolsCount < 2 || value.startsWith(' '))
      return false

    const reg = /[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêệếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạầảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựýýỳỵỷỹAÁẢÀÃẠÂẤẨẦẪẬĂẮẲẰẴẶOÓỎÒÕỌÔỐỔỒỖỘƠỚỞỜỠỢEÉẺÈẼẸÊẾỂỀỄỆUÚỦÙŨỤƯỨỬỪỮỰIÍỈÌĨỊĐaáảàãạâấẩầẫậăắẳằẵặoóỏòõọôốổồỗộơớởờỡợeéẻèẽẹêếểềễệuúủùũụưứửừữựiiỉìĩịđ]/
    return !(value.match(reg))
  })

  $.validator.addMethod('vidate', function (value, element) {
    return this.optional(element) || value.match(/^(0[1-9]|[12][0-9]|3[01])[\/|-](0[1-9]|1[012])[\/|-](19\d\d|2\d\d\d)$/)
  })

  $.validator.addMethod('exactlength', function (value, element, params) {
    return this.optional(element) || (/^[0123456789\s]*$/.test(value) &&
      params.includes(value.replace(/\D+/g, '').length))
  })

  $.validator.addMethod('min_length_salary', (value, _element, _params) => {
    return value.replace(/\D/g, '').length >= 7
  })

  $.validator.addMethod('sameNumbers', (value, _element) => {
    const phone_numbers = value.replace(/\D+/g, '')
    let no_same_numbers = true

    for (let letter_count = 0, end = phone_numbers.length - 4, asc = end >= 0; asc ? letter_count <= end : letter_count >= end; asc ? letter_count++ : letter_count--) {
      if ((phone_numbers[letter_count] === phone_numbers[letter_count + 1]) &&
        (phone_numbers[letter_count] === phone_numbers[letter_count + 2]) &&
        (phone_numbers[letter_count] === phone_numbers[letter_count + 3]) &&
        (phone_numbers[letter_count] === phone_numbers[letter_count + 4]))
        no_same_numbers = false
    }
    return no_same_numbers
  }, translations['errors.messages.phoneShouldByValid'])

  $.validator.addMethod('dontNumbersConsecutive', (value, _element) => {
    const phone_numbers = value.replace(/\D+/g, '')
    let no_numbers_consecutive = true

    if (phone_numbers >= 6) {
      for (let letter_count = 0, end = phone_numbers.length - 5, asc = end >= 0; asc ? letter_count <= end : letter_count >= end; asc ? letter_count++ : letter_count--) {
        if (phone_numbers.substr(letter_count, 6) === '123456')
          no_numbers_consecutive = false
      }
    }
    return no_numbers_consecutive
  }, translations['errors.messages.phoneShouldByValid'])

  $.validator.methods.email = function (value, element) {
    if (this.optional(element)) return true

    const matches = value.match(/^[a-zA-Z\d][a-zA-Z\d\.\+\-_]{0,63}@([a-zA-Z\d\-]{1,63}\.)+[a-zA-Z\d\-]{1,62}[a-zA-Z\d]$/)

    return ((!!matches && matches[0].length === value.length) && value.length <= 250)
  }

  $.validator.addMethod('companyPhone', function (value, element) {
    const clearPhone = value.replace(/\D+/g, '')
    const regex = /(^0[35789]\d{8}$)|(^02\d{8,9}$)/

    return this.optional(element) || (clearPhone.match(regex))
  }, translations['errors.messages.phoneShouldByValid'])

  $.validator.addMethod('maxlengthOnlyDigits', (value, _, params) => {
    value = value.replace(/\D+/g, '')

    return value.length <= params
  }, $.validator.messages.maxlength)

  $.validator.addMethod('BankAccountNumberCard', (value, _element) => {
    return !(value.startsWith(9704))
  }, $.validator.messages.BankAccountNumberCard)

  $.validator.addMethod('notEqualToCompanyPhone', (value, _element) => {
    const guarantorPhone = value.replace(/\D+/g, '')
    const companyPhone = $('#application_company_phone')
      ?.val()
      ?.replace(/\D+/g, '')

    return guarantorPhone !== companyPhone
  }, translations['errors.messages.notEqualToCompanyPhone'])

  $.validator.addMethod('notEqualToGuarantorPhone', (value, _element) => {
    const companyPhone = value.replace(/\D+/g, '')
    const guarantorPhone = $('#application_guarantor_phone')
      .val()
      .replace(/\D+/g, '')

    return guarantorPhone !== companyPhone
  }, translations['errors.messages.notEqualToGuarantorPhone'])

  $.validator.addMethod('minStrict', (value, el, param) => {
    return value.replace(/\D+/g, '') >= param
  }, $.validator.messages.minlength)

  $.validator.addMethod('maxStrict', (value, el, param) => {
    return value.replace(/\D+/g, '') <= param
  }, $.validator.messages.maxlength)

  $.validator.addMethod('validBankCardNumber', (value, _, _params) => {
    value = value.charAt(0)

    return ['2', '3', '4', '5', '9'].includes(value)
  }, translations['errors.messages.validBankCardNumber'])

  $.validator.addMethod('validSalaryMin', (value, element, params) => {
    const only_digits = value.split(/\D/)
      .join('')
    if (only_digits.length === 1 && only_digits[0] === '0') return true
    return only_digits.length >= params
  })

  $.validator.addMethod('validSalaryMax', (value, element, params) => {
    const only_digits = value.split(/\D/)
      .join('')
    if (only_digits.length === 1 && only_digits[0] === '0') return true
    return only_digits.length <= params
  })

  $.validator.addMethod('validDate', (value, _element) => {
    const parts = value.split('-')
    const js_date = new Date(parts[2], parts[1] - 1, parts[0])

    const day = js_date.getDate() < 10 ? `0${js_date.getDate()}` : js_date.getDate()
    const month = js_date.getMonth() + 1 < 10 ? `0${js_date.getMonth() + 1}` : js_date.getMonth() + 1
    const year = js_date.getFullYear()

    const full_parsed_date = `${day.toString()}-${month.toString()}-${year.toString()}`

    return full_parsed_date === value
  })
})()
